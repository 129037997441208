<template>
  <div>
    <h1>测试页面</h1>
    <button @click="startLocation()">启动定位</button>
    <button @click="stopLocation()">关闭定位</button>
    <button @click="getCurrentLocation()">获取当前位置</button>
    <button @click="setTargetLocation()">设置提醒位置</button>
    <button @click="runFence()">电子围栏</button>
    <button @click="updateApp()">更新应用</button>
  </div>
</template>

<script>
export default {
  methods: {
    async getCurrentLocation() {
      try {
        let data = await Capacitor.Plugins.AMapLocation.getCurrentLocation();
        alert(JSON.stringify(data));
      } catch (error) {
        alert(error.message);
      }
    },
    async setTargetLocation() {
      try {
        // await Capacitor.Plugins.AMapLocation.setTargetLocation({
        //   latitude: 119.619397,
        //   longitude: 32.168554,
        // });
        await Capacitor.Plugins.AMapLocation.setTargetLocation({
          latitude: 30.2025,
          longitude: 120.2484,
        });
      } catch (error) {
        alert(error.message);
      }
    },
    async startLocation() {
      try {
        let data = await Capacitor.Plugins.AMapLocation.start();
        alert(JSON.stringify(data));
      } catch (error) {
        alert(error.message);
      }
    },
    async stopLocation() {
      try {
        await Capacitor.Plugins.AMapLocation.stop();
      } catch (error) {
        alert(error.message);
      }
    },
    async runFence() {
      try {
        let data =  await Capacitor.Plugins.GeoFencePlugin.start();
        alert(JSON.stringify(data));
      } catch (error) {
        alert(error.message);
      }
    },
    async updateApp() {
      try {
        var option = {
          portName: 'system.foundation.Dic.getDicDetail',
          data: {code: 'andorid_apk'},
          needLoading: false,
          successCallback: (data) => {
            var entity = data.entities[0];
            Capacitor.Plugins.AppUpdate.checkUpdate({
              version: entity.value,
              downloadUrl: entity.dicAttach[0].url,
              desc: entity.des,
            });
          }
        }
        this.$sapi.callPort(option);
      } catch (error) {
        alert(error.message);
      }
    },
  },
};
</script>

<style scoped>
</style>